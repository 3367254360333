import { useState, useEffect, useRef } from "react";
import useTokenMarket from "../../../hook/use-tokenMarket";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Input,
} from "@material-tailwind/react";
import { getGames, getGameTokens } from "../../../services/market";
import GameToken from "./token";
import DetailModal from "./DetailModal";

const Table = () => {
  const [data, setData] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [item, setItem] = useState(10);
  const refCurrentPage = useRef();
  const [defiId, setDefiId] = useState(99999999999999);
  const [showModal, setShowModal] = useState(false);
  

  const formatNumber = (number) => {
    return number.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
  };

  const getGameInfos = async () => {
    const res = await getGames();
    if (res) {
      setData(res);
      setUpdateData(res.slice(0, 10));
    }
  };

  useEffect(() => {
    refCurrentPage.current.value = 1;
    getGameInfos();
  }, []);

  const search = (keyword) => {
    refCurrentPage.current.value = 1;
    const filteredData = data.filter((v) =>
      v.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setUpdateData(filteredData.slice(0, item));
  };

  const handleItem = (count) => {
    setItem(count);
    setUpdateData(
      data.slice(
        count * (refCurrentPage.current.value - 1),
        count * refCurrentPage.current.value
      )
    );
  };

  const movePage = (status) => {
    if (status === "next") {
      refCurrentPage.current.value++;
      setUpdateData(
        data.slice(
          item * (refCurrentPage.current.value - 1),
          item * refCurrentPage.current.value
        )
      );
    } else {
      refCurrentPage.current.value--;
      setUpdateData(
        data.slice(
          item * (refCurrentPage.current.value - 1),
          item * refCurrentPage.current.value
        )
      );
    }
  };

  const closeModal = () => {
    setShowModal(false);
  }

  const showGameDetail = (id) => {
    setShowModal(true);
    setDefiId(id)
  }

  return (
    <div className="flex justify-center pb-[80px] text-[14px] min-h-screen bg-[#f8f8f8] text-[#333]">
      <div className={`${!showModal && 'hidden'}  bg-[#000000] absolute w-full h-full opacity-20 z-20`}></div>
      <Card className={`flex justify-center pt-[170px] h-full w-[900px] xl:w-[1100px] bg-[#f8f8f8] ${showModal ? 'opacity-10' : ''}`}>
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none bg-[#f8f8f8]"
        >
          <div className="mb-[30px] md:flex justify-between items-center w-full px-[30px] xl:px-[50px]">
            <div>
              <p className="font-bold text-[30px] text-[#333]">GameFi</p>
            </div>
            <div className="w-[290px]">
              <Input
                icon={
                  <MagnifyingGlassIcon className="absolute h-[18px] top-[12px] left-[255px]" />
                }
                placeholder="search"
                className="flex items-center pl-[14px] py-[10px] rounded-sm"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    search(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className="flex flex-col gap-y-[30px] font-medium text-[14px]">
          {updateData.map(
            (
              {
                dappId,
                name,
                description,
                logo,
                website,
                metrics,
                socialLinks,
                chains,
              },
              index
            ) => {
              return (
                <button className="bg-white py-[25px] px-[40px]" onClick={() => showGameDetail(dappId)} >
                  <div className="flex items-center gap-x-[20px] xl:gap-x-[40px] mb-[5px]">
                    <p className="font-bold text-[20px]">{name}</p> 
                    <p className="font-medium">{chains[0]}</p>
                  </div>
                  <p className="text-[12px] text-[#44444]">{description}</p>
                  <div className="flex items-center py-[10px] gap-x-[20px] xl:gap-x-[40px]">
                    <div className="w-[200px]">
                      <a href={website}>
                        <img src={logo} alt="logo" />
                      </a>
                    </div>
                    <div className="flex flex-col jsutify-cneter gap-y-[5px] w-[120px]">
                      {/* <GameToken dappId={dappId} /> */}
                      <p className="text-[12px] text-[#555]">Token</p>
                      <p>-</p>
                      <p>-</p>
                    </div>
                    <div className="flex w-full justify-between">
                      <div className="flex flex-col gap-y-[5px]">
                        <p className="text-[12px] text-[#555]">
                          UAW / 24H Change
                        </p>
                        <p>{metrics.uaw}</p>
                        <p
                          className={`text-[12px]  ${
                            metrics.uawPercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }`}
                        >
                          {metrics.uawPercentageChange}%
                        </p>
                      </div>

                      <div className="flex flex-col gap-y-[5px]">
                        <p className="text-[12px] text-[#555]">
                          Volume / 24H Change
                        </p>
                        <p>{metrics.volume}</p>
                        <p
                          className={`text-[12px]  ${
                            metrics.volumePercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }`}
                        >
                          {metrics.volumePercentageChange}%
                        </p>
                      </div>
                      <div className="flex flex-col gap-y-[5px]">
                        <p className="text-[12px] text-[#555]">
                          Txs / 24H Change
                        </p>
                        <p>{metrics.transactions}</p>
                        <p
                          className={`text-[12px]  ${
                            metrics.transactionsPercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }`}
                        >
                          {metrics.transactionsPercentageChange}%
                        </p>
                      </div>
                      <div className="flex flex-col gap-y-[5px]">
                        <p className="text-[12px] text-[#555]">
                          Balance / 24H Change
                        </p>
                        <p>{metrics.balance}</p>
                        <p
                          className={`text-[12px]  ${
                            metrics.balancePercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }`}
                        >
                          {metrics.balancePercentageChange}%
                        </p>
                      </div>
                    </div>
                  </div>
                </button>
              );
            }
          )}
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50">
          <div className="flex justify-center items-center w-screen p-5">
            <div className="flex justify-center items-center mt-8">
              <button
                className="text-gray-70 border border-gray-300 rounded-md focus:outline-none dark:border-none flex justify-center items-center h-[35px] w-[40px]"
                onClick={() =>
                  refCurrentPage.current.value > 1 && movePage("prev")
                }
              >
                <svg className="h-6 w-5 fill-current" viewBox="0 0 20 20">
                  <path d="M10 18l-8-8 8-8 1.414 1.414L4.828 10l6.586 6.586L10 18z" />
                </svg>
              </button>
              <div className="flex gap-2 mx-[10px] sm:mx-[15px]">
                <input
                  className="h-[35px] w-[70px] border border-gray-300 rounded-md text-center"
                  ref={refCurrentPage}
                  onChange={(e) =>
                    (refCurrentPage.current.value = e.target.value)
                  }
                />
              </div>
              <button
                className="text-gray-70 border border-gray-300 rounded-md flex justify-center items-center h-[35px] w-[40px]"
                onClick={() =>
                  refCurrentPage.current.value < 10 && movePage("next")
                }
              >
                <svg className="h-6 w-5  fill-current" viewBox="0 0 20 20">
                  <path d="M10 2l8 8-8 8-1.414-1.414L15.172 10l-6.586-6.586L10 2z" />
                </svg>
              </button>
              <span className="ml-4 sm:ml-14 mr-4 dark:text-gray-400">
                Page
              </span>
              <select
                className="border border-gray-300 rounded-md w-[70px] flex justify-center items-center h-[35px] focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-white"
                onChange={(e) => handleItem(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        </CardFooter>
      </Card>
      <DetailModal open={showModal} close={closeModal} dappId={defiId} />

    </div>
  );
};

export default Table;
