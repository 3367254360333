import React from "react";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { useRouter } from "../../hook/use-router";

const Notice1 = () => {
  const { t, i18n } = useTranslation();
  const router = useRouter();

  const toHome = () => {
    router.push("/");
  };

  return (
    <div className="flex justify-center w-full">
      <div
        className={`py-[180px] text-[16px] leading-[1.8] tracking-wider text-[#222] w-[320px] sm:w-[400px] md:w-[600px] xl:w-[800px] ${
          i18n.language === "cn" && "font-thin"
        } `}
        style={{
          fontFamily:
            i18n.language === "jp"
              ? "YuGothic"
              : i18n.language === "en"
              ? "system-ui"
              : "YuGothicMd",
        }}
      >
        <p className="text-[30px] font-bold whitespace-pre-wrap mb-[30px]">
          {t("notice1")}
        </p>
        <p className="whitespace-pre-wrap">{t("notice2")}</p>
        <button
          onClick={toHome}
          className="bg-[#cd162c] text-white px-[80px] py-[10px] rounded-md mx-auto flex my-[60px]"
        >
          {t("notice3")}
        </button>
      </div>
    </div>
  );
};

export default Notice1;
