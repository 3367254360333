
const Footer = () => {
    return (
        <div className="absolute flex justify-center items-center bg-[#333] w-full py-[20px] bottom-0">
            <p className="text-white text-[12px] md:text-[14px] xl:text-[16px]">Copyright © 2024 AI on Web3 All Rights Reserved</p>
        </div>
    )
};

export default Footer;
