import React from 'react';
import axios from "axios";
import { BASE_URL } from "../lib/config";



const PdfGenerator = ({formData}) => {
  const handleGeneratePdf = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/api/reports/generate-report`, {
        clientName: 'Allen Chow',
        productName: 'AI_Powered Web3 Solution',
        coins: formData
      }, {
        responseType: 'blob', // Important to handle the response as a blob
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report.pdf'); // or any other extension
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div>
      <button onClick={handleGeneratePdf} className='w-[60px]'>
        <img src='/images/pdf.png' alt='' />
      </button>
    </div>
  );
};

export default PdfGenerator;