import "../../i18n";

const Home = () => {
  return (
    <div className="h-screen flex flex-col relative">
      <div
        className="relative flex flex-col justify-center items-center h-full"
        id="home"
      >
        <video
          data-aos="fade-in"
          data-aos-once="true"
          data-aos-delay="700"
          className="absolute left-0 w-full object-cover h-screen"
          src="/images/pexels_videos_1957727-1080p.mp4"
          autoPlay
          loop
          muted
        />
        {/* <img className="w-10/12 mx-auto 2xl:-mb-20" src="/images/1.jpg" alt=""/> */}
        <div
          className="flex justify-center items-center font-bold mx-10 text-white w-[350px] sm:w-[500px] md:w-[700px] xl:w-[1000px]"
          data-aos="fade-in"
          data-aos-once="true"
          data-aos-delay="700"
        >
          <p
            className="text-center sm:leading-[50px] md:leading-[68px] xl:leading-[80px] pb-[10px] pt-[130px] sm:text-[27px] md:text-[46px] xl:text-[55px]"
            style={{ fontFamily: "system-ui" }}
          >
            Boost Your GameFi and Web3 Products with Cutting-Edge AI Insights
            and Worldwide <br /> Audience Engagement
          </p>
        </div>
      </div>

      <div className="absolute h-[100px] bottom-0" id="services"></div>
    </div>
  );
};

export default Home;
