import { useState, useEffect } from "react";
import { BASE_URL } from "../lib/config";

const useCoinMarket = () => {
  const [state, setState] = useState({ data: [], isLoading: true });
  const updateState = (data) => {
    setState((state) => ({
      data: data ? data : state.data,
      isLoading: false,
    }));
  };

  async function init() {
    try {
      // const res = await fetch("https://zoomies.bet/api");
      const res = await fetch(`${BASE_URL}/api/market/coin`);
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }
      const datas = await res.json();
      const updatedDatas = [];

      for (let i = 0; i < datas.length; i++) {
        const updateData = {
          ...datas[i],
          symbol: datas[i].symbol.toLowerCase(),
        };
        updatedDatas.push(updateData);
      }

      updateState(updatedDatas);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    init();
    const id = setInterval(() => {
      init();
    }, 48 * 60 * 1000);
    return () => clearInterval(id);
  }, []);
  return state;
};

export default useCoinMarket;
