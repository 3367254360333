import axios from "axios";
import { BASE_URL } from "../lib/config";

export const getGames = async () => {
  try {
    // const params = {
    //   page,
    //   resultsPerPage: items,
    // };
    const res = await axios.get(`${BASE_URL}/api/market/game`, {
      // params,
    });
    const data = res.data;
    return data;
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};

export const getGameTokens = async (dappId, range) => {
  try {
    const res = await axios.get(`${BASE_URL}/api/market/gameToken/${dappId}/${range}`, {
    });
    const data = res.data;
    return data;
  } catch (error) {
    console.log("Error fetching data:", error);
  }
};
