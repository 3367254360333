import { useState, useEffect } from "react";
import { getGameTokens } from "../../../services/market";

const Detail = (props) => {
  const [gameData, setGameData] = useState(props.data);
  const [range, setRange] = useState("24h");

  useEffect(() => {
    setGameData(props.data);
  }, [props.data]);

  const formatNumber = (number) => {
    return number.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
  };

  const getTokens = async () => {
    const res = await getGameTokens(props.dappId, range);
    if (res) {
      setGameData(res);
    }
  };

  useEffect(() => {
    getTokens();
  }, [props.dappId, range]);

  return (
    <>
      <div
        id="default-modal"
        tabindex="-1"
        className={`${
          !props.open && "hidden"
        } flex overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 max-h-full text-white shadow-lg`}
      >
        {gameData && (
          <div className="w-[1000px] xl:w-[1200px] p-4 h-3/4 ">
            <div className="bg-white rounded-lg  dark:bg-gray-700 mt-[550px] md:mt-0">
              <div className="flex items-center justify-between px-[30px] py-[15px] border-b rounded-t dark:border-gray-600 bg-[#2a92d3]">
                <h3 className="text-xl font-semibold text-white">
                  Game Detail
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="default-modal"
                  onClick={props.close}
                >
                  <svg
                    className="w-3 h-3 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="md:flex px-[35px] py-[25px] justify-between gap-x-[20px]">
                <div className="flex flex-col gap-[14px] w-full md:w-2/3 mb-[20px]">
                  <div className="flex bg-[#00436c] rounded-xl px-[30px] py-[20px] space-x-[20px]">
                    <img className="rounded-xl" src={gameData.logo} alt="" />
                    <div className="py-[10px]">
                      <p className="font-bold text-[24px] mb-[7px]">
                        {gameData.name}
                      </p>
                      <p className="text-white font-medium mb-[3px]">
                        {gameData.chains[0]}
                      </p>
                      <p className="text-[#dbd8d8]">{gameData.description}</p>
                    </div>
                  </div>
                  <div className="bg-[#00436c] rounded-xl px-[30px] py-[20px]">
                    <div className="border-[#0c5b8b] border-b-[1px] pb-[8px]">
                      <p className="text-[#dbd8d8] text-[14px]">
                        {gameData.name}
                      </p>
                      <p className="text-white text-[22px] font-medium">
                        Game Stats
                      </p>
                    </div>
                    <div className="flex justify-between py-[15px]">
                      <div className="flex space-x-[2px] px-[4px] py-[2px] bg-[#0c5b8b] rounded-md z-30 bg-opacity-40">
                        <button
                          className="flex justify-center items-center px-[7px] py-[3px] rounded-md hover:bg-[#999999]"
                          value={1}
                          onClick={() => setRange("24h")}
                        >
                          24h
                        </button>
                        <button
                          className="flex justify-center items-center px-[7px] py-[3px] rounded-md hover:bg-[#999999]"
                          value={7}
                          onClick={() => setRange("7d")}
                        >
                          7d
                        </button>
                        <button
                          className="flex justify-center items-center px-[7px] py-[3px] rounded-md hover:bg-[#999999]"
                          value={30}
                          onClick={() => setRange("30d")}
                        >
                          30d
                        </button>
                      </div>
                      <div></div>
                    </div>
                    <div className="flex gap-x-[10px]">
                      <div className="bg-[#0c5b8b] bg-opacity-40 rounded-md w-1/4 px-[10px] py-[7px]">
                        <div className="flex justify-between items-center">
                          <p className="text-[12px] text-[#dbd8d8]">UAW</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            height="17"
                            width="17"
                            viewBox="0 0 24 24"
                            color="neutral4"
                            className="sc-4c05d6ef-0 fFPkto text-white mt-[4px]"
                          >
                            <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                          </svg>
                        </div>
                        <p className="text-[18px] font-medium">
                          {gameData.metrics.uaw}
                        </p>
                        <p
                          className={`text-[12px] font-medium ${
                            gameData.metrics.uawPercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }
                          `}
                        >
                          {gameData.metrics.uawPercentageChange}%
                        </p>
                      </div>
                      <div className="bg-[#0c5b8b] bg-opacity-40 rounded-md w-1/4 px-[10px] py-[7px]">
                        <div className="flex justify-between items-center">
                          <p className="text-[12px] text-[#dbd8d8]">
                            transactions
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            height="17"
                            width="17"
                            viewBox="0 0 24 24"
                            color="neutral4"
                            className="sc-4c05d6ef-0 fFPkto text-white mt-[4px]"
                          >
                            <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                          </svg>
                        </div>
                        <p className="text-[18px] font-medium">
                          {gameData.metrics.transactions}
                        </p>
                        <p
                          className={`text-[12px] font-medium ${
                            gameData.metrics.transactionsPercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }
                          `}
                        >
                          {gameData.metrics.transactionsPercentageChange}%
                        </p>
                      </div>
                      <div className="bg-[#0c5b8b] bg-opacity-40 rounded-md w-1/4 px-[10px] py-[7px]">
                        <div className="flex justify-between items-center">
                          <p className="text-[12px] text-[#dbd8d8]">Volume</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            height="17"
                            width="17"
                            viewBox="0 0 24 24"
                            color="neutral4"
                            className="sc-4c05d6ef-0 fFPkto text-white mt-[4px]"
                          >
                            <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                          </svg>
                        </div>
                        <p className="text-[18px] font-medium">
                          {gameData.metrics.volume}
                        </p>
                        <p
                          className={`text-[12px] font-medium ${
                            gameData.metrics.volumePercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }
                          `}
                        >
                          {gameData.metrics.volumePercentageChange}%
                        </p>
                      </div>
                      <div className="bg-[#0c5b8b] bg-opacity-40 rounded-md w-1/4 px-[10px] py-[7px]">
                        <div className="flex justify-between items-center">
                          <p className="text-[12px] text-[#dbd8d8]">Balance</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            height="17"
                            width="17"
                            viewBox="0 0 24 24"
                            color="neutral4"
                            className="sc-4c05d6ef-0 fFPkto text-white mt-[4px]"
                          >
                            <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                          </svg>
                        </div>
                        <p className="text-[18px] font-medium">
                          {gameData.metrics.balance}
                        </p>
                        <p
                          className={`text-[12px] font-medium ${
                            gameData.metrics.balancePercentageChange > 0
                              ? "text-[#059142]"
                              : "text-[#ff2c2c]"
                          }
                          `}
                        >
                          {gameData.metrics.balancePercentageChange}%
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col bg-[#00436c] rounded-xl px-[30px] py-[20px]">
                    <div className="border-[#0c5b8b] border-b-[1px] pb-[8px]">
                      <p className="text-white text-[20px] font-medium">
                        What is {gameData.name}?
                      </p>
                    </div>
                    <div className="flex flex-col gap-y-[20px] pt-[10px]">
                      <div dangerouslySetInnerHTML={{ __html: gameData.fullDescription }}></div>
                      <p>Website: {gameData.website}</p>
                      <div className="flex gap-x-[10px] items-center">
                        <p>Tags</p>
                        {gameData.tags.map((tag) => (
                          <p className="bg-[#0c5b8b] rounded-2xl bg-opacity-40 px-[10px]">
                            {tag.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-y-[12px] w-full md:w-1/3">
                  <a
                    href={gameData.website}
                    className="flex rounded-md bg-[#2a92d3] text-white w-full justify-center items-center py-[10px] space-x-[8px]"
                  >
                    <svg
                      width="21"
                      height="20"
                      viewBox="0 0 21 20"
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-white pt-[2px]"
                    >
                      <path d="M11.5 3a1 1 0 1 0 0 2h2.586l-6.293 6.293a1 1 0 1 0 1.414 1.414L15.5 6.414V9a1 1 0 1 0 2 0V4a1 1 0 0 0-1-1h-5Z"></path>
                      <path d="M5.5 5a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2v-3a1 1 0 1 0-2 0v3h-8V7h3a1 1 0 0 0 0-2h-3Z"></path>
                    </svg>
                    <p className="font-medium text-[17px]">Open dapp</p>
                  </a>
                  <div className="bg-[#00436c] rounded-md px-[25px] py-[20px]">
                    <div className="border-[#0c5b8b] border-b-[1px] pb-[8px]">
                      <p className="text-[17px] font-bold">Socials</p>
                    </div>
                    <div className="flex flex-wrap mt-[15px] space-x-[15px] text-[12px] mb-[10px]">
                      {gameData.socialLinks.map((social) => (
                        <a href={social.url} className="hover:text-[#0c5b8b]">
                          {social.title}
                        </a>
                      ))}
                    </div>
                  </div>
                  <div className="bg-[#00436c] rounded-md px-[25px] py-[20px]">
                    <div className="border-[#0c5b8b] border-b-[1px]  pb-[8px]">
                      <p className="text-[#dbd8d8] text-[14px]">
                        {gameData.name}
                      </p>
                      <p className="text-white text-[20px] font-medium">
                        Tokens
                      </p>
                    </div>
                    <div className="mt-[20px] gap-y-[20px]">
                      {gameData.tokens &&
                        gameData.tokens.map((token) => (
                          <div className="flex justify-between items-center">
                            <div className="flex items-center gap-x-[10px]">
                              <img
                                src={token.logo}
                                alt=""
                                className="w-[40px]"
                              />
                              <p className="mb-[5px] font-medium">
                                {token.symbol}
                              </p>
                            </div>
                            <div>
                              <p className="font-medium text-[17px]">
                                $ {token.price}
                              </p>
                              <p
                                className={` font-medium text-[14px] ${
                                  token.pricePercentageChange > 0
                                    ? "text-[#059142]"
                                    : "text-[#ff2c2c]"
                                }`}
                              >
                                {token.pricePercentageChange}%
                              </p>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Detail;
