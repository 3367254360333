import { useState, useEffect, useRef } from "react";
import useCoinMarket from "../../../hook/use-coinMarket";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  CardFooter,
  Input,
} from "@material-tailwind/react";
import CoinPriceChart from "./PriceChart";
import GenerateReport from "../../../components/PdfGenerator"
import Detail from "./Detail";

const TABLE_HEAD = [
  "#",
  "Name",
  "Price",
  "1h%",
  "24h%",
  "7d%",
  "Market Cap",
  "volume(24h)",
  "Circulating Supply",
  "Last 7 Days",
];

const Table = () => {
  const { data, isLoading } = useCoinMarket();
  const [updateData, setUpdateData] = useState([]);
  const [item, setItem] = useState(10);
  const refCurrentPage = useRef();
  const [selectedIndex, setSelectedIndex] = useState(999999999999999);
  const [show, setShow] = useState(false);

  const formatNumber = (number) => {
    return number.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
  };

  useEffect(() => {
    refCurrentPage.current.value = 1;
  }, [data]);

  useEffect(() => {
    setUpdateData([...data].slice(0, 10));
  }, [data]);

  // useEffect(() => {
  //   getData(keyword);
  // }, [data, keyword, item]);

  const search = (keyword) => {
    refCurrentPage.current.value = 1;
    const filteredData = data.filter((v) =>
      v.name.toLowerCase().includes(keyword.toLowerCase())
    );
    setUpdateData(filteredData.slice(0, item));
  };

  const handleItem = (count) => {
    setItem(count);
    setUpdateData(
      data.slice(
        count * (refCurrentPage.current.value - 1),
        count * refCurrentPage.current.value
      )
    );
  };

  const movePage = (status) => {
    if (status === "next") {
      refCurrentPage.current.value++;
      setUpdateData(
        data.slice(
          item * (refCurrentPage.current.value - 1),
          item * refCurrentPage.current.value
        )
      );
    } else {
      refCurrentPage.current.value--;
      setUpdateData(
        data.slice(
          item * (refCurrentPage.current.value - 1),
          item * refCurrentPage.current.value
        )
      );
    }
  };

  const closeModal = () => {
    setShow(false);
  }

  const selectTr = (index) => {
    setShow(true);
    setSelectedIndex(index)
  }

  return (
    <div className={`flex justify-center pb-[80px] text-[14px] min-h-screen text-[#222] relative`}>
      <div className={`${!show && 'hidden'}  bg-[#000000] absolute w-full h-full opacity-20 z-20`}></div>
      <Card className={`flex justify-center h-full w-full px-[20px] sm:px-[50px] md:px-[100px] xl:px-[200px] ${show ? 'opacity-10' : ''} mt-[170px]`}>
        
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-[30px] md:flex justify-between items-center w-full px-[30px] xl:px-[50px]">
            <div>
              <p className="font-bold text-[30px] text-[#333]">Coin Market</p>
            </div>
            <GenerateReport formData={updateData.slice(0, 3)} />
            <div className="w-[290px]">
              <Input
                icon={
                  <MagnifyingGlassIcon className="absolute h-[18px] top-[12px] left-[255px]" />
                }
                placeholder="search"
                className="flex items-center pl-[14px] py-[10px] rounded-sm"
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    search(e.target.value);
                  }
                }}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className="px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th key={head} className="border-y bg-blue-gray-50/50 p-4">
                    <Typography
                      variant="small"
                      className="font-bold leading-none flex"
                    >
                      {head}{" "}
                      {(head.includes("Market") ||
                        head.includes("volume") ||
                        head.includes("Circulating")) && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          height="16"
                          width="16"
                          viewBox="0 0 24 24"
                          color="neutral4"
                          className="sc-4c05d6ef-0 fFPkto text-[#444]"
                        >
                          <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                        </svg>
                      )}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {updateData.map(
                (
                  { id, name, slug, symbol, circulating_supply, quote },
                  index
                ) => {
                  const isLast = index === updateData.length - 1;
                  const classes = isLast
                    ? "px-4"
                    : "px-4 border-b border-blue-gray-50";

                  return (
                    <tr key={name} className="cursor-pointer h-[120px]" onClick={() => selectTr(index)}>
                      <td className={classes}>
                        <Typography variant="small" className="font-normal">
                          {item * (refCurrentPage.current.value - 1) +
                            index +
                            1}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center">
                          <div className="flex items-center w-[45px]">
                            <img
                              src={`https://cryptologos.cc/logos/${slug}-${symbol}-logo.svg?v=032`}
                              alt={id}
                              className="h-[25px] w-[25px]"
                            />
                          </div>
                          <div>
                            <Typography variant="small" className="font-bold">
                              {name.slice(0, 12)}
                            </Typography>
                            <Typography
                              variant="small"
                              className="font-bold text-[#777] uppercase"
                            >
                              {symbol.slice(0, 12)}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-bold">
                          ${formatNumber(Number(quote.USD.price.toFixed(2)))}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-bold">
                          {formatNumber(
                            Number(quote.USD.percent_change_1h.toFixed(2))
                          )}
                          %
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-bold">
                          {formatNumber(
                            Number(quote.USD.percent_change_24h.toFixed(2))
                          )}
                          %
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-bold">
                          {formatNumber(
                            Number(quote.USD.percent_change_7d.toFixed(2))
                          )}
                          %
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-bold">
                          $
                          {formatNumber(
                            Number(quote.USD.market_cap.toFixed(0))
                          )}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-bold">
                          $
                          {formatNumber(
                            Number(quote.USD.volume_24h.toFixed(0))
                          )}
                        </Typography>
                        <Typography
                          variant="small"
                          className="font-bold text-[12px] text-[#777]"
                        >
                          {formatNumber(
                            Number(
                              (quote.USD.volume_24h / quote.USD.price).toFixed(
                                0
                              )
                            )
                          )}{" "}
                          <span className="uppercase">{symbol}</span>
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography variant="small" className="font-bold">
                          {formatNumber(Number(circulating_supply.toFixed(0)))}{" "}
                          <span className="uppercase">{symbol}</span>
                        </Typography>
                      </td>
                      <td className={classes}>
                        {/* <CoinPriceChart name={name} /> */}
                      </td> 
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50">
          <div className="bg-white flex justify-center items-center w-screen p-5">
            <div className="flex justify-center items-center mt-8">
              <button
                className="text-gray-70 border border-gray-300 rounded-md focus:outline-none dark:border-none flex justify-center items-center h-[35px] w-[40px]"
                onClick={() =>
                  refCurrentPage.current.value > 1 && movePage("prev")
                }
              >
                <svg className="h-6 w-5 fill-current" viewBox="0 0 20 20">
                  <path d="M10 18l-8-8 10-8 1.414 1.414L4.828 10l6.586 6.586L10 18z" />
                </svg>
              </button>
              <div className="flex gap-2 mx-[10px] sm:mx-[15px]">
                <input
                  className="h-[35px] w-[70px] border border-gray-300 rounded-md text-center"
                  ref={refCurrentPage}
                  onChange={(e) =>
                    (refCurrentPage.current.value = e.target.value)
                  }
                />
              </div>
              <button
                className="text-gray-70 border border-gray-300 rounded-md flex justify-center items-center h-[35px] w-[40px]"
                onClick={() =>
                  refCurrentPage.current.value < 1000 && movePage("next")
                }
              >
                <svg className="h-6 w-5  fill-current" viewBox="0 0 20 20">
                  <path d="M10 2l8 8-10 8-1.414-1.414L15.172 10l-6.586-6.586L10 2z" />
                </svg>
              </button>
              <span className="ml-4 sm:ml-14 mr-4 dark:text-gray-400">
                Page
              </span>
              <select
                className="border border-gray-300 rounded-md w-[70px] flex justify-center items-center h-[35px] focus:outline-none dark:border-gray-700 dark:bg-gray-800 dark:text-white"
                onChange={(e) => handleItem(e.target.value)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        </CardFooter>
      </Card>
      <Detail open={show} close={closeModal} data={updateData[selectedIndex]} />
    </div>
  );
};

export default Table;
