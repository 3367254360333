import "../../i18n";
import { useTranslation } from "react-i18next";

const Company = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center bg-[url('D:\workspace\AllenChow\AiOnWeb3(react)\public\images\company.jpg')] bg-cover bg-no-repeat bg-center relative">
      <div className="absolute bg-[#2a92d3] bg-opacity-95 top-0 w-full h-full z-10"></div>
      <div className="flex justify-center py-[50px] gap-x-[120px]">
        <div className="h-full bg-white z-40 pb-[50px] w-[350px] sm:w-[500px] md:w-[700px] xl:w-[1200px]">
          <div className="text-center py-[50px]">
            <p
              className="text-[40px] md:text-[60px] xl:text-[80px] text-[#2a92d39d] font-normal"
              style={{ fontFamily: "system-ui" }}
            >
              COMPANY
            </p>
            <p
              className="text-[14px] md:text-[20px] font-bold"
              style={{ fontFamily: "YuGothic" }}
            >
              会社概要
            </p>
          </div>
          <div
            className="px-[20px] sm:px-[40px] md:px-[100px] xl:px-[200px]"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="700"
          >
            <div className="bg-[url('D:\workspace\AllenChow\AiOnWeb3(react)\public\images\blueBack.png')] bg-cover bg-no-repeat px-[32px] py-[2px]">
              <p className="text-white font-bold md:text-[20px]">
                {t("comT3")}
              </p>
            </div>
            <div className="flex border-b-[1.5px] border-[#2a92d3] pt-[30px] pb-[15px] mx-[20px] px-[3px] gap-x-[50px]">
              <p className="w-1/4">{t("comT4")}</p>
              <p className="w-3/4">{t("comT5")}</p>
            </div>
            <div className="flex border-b-[1.5px] border-[#2a92d3] pt-[30px] pb-[15px] mx-[20px] px-[3px] gap-x-[50px]">
              <p className="w-1/4 whitespace-pre-wrap">{t("comT6")}</p>
              <p className="w-3/4">{t("comT7")}</p>
            </div>
            <div className="flex border-b-[1.5px] border-[#2a92d3] pt-[30px] pb-[15px] mx-[20px] px-[3px] gap-x-[50px]">
              <p className="w-1/4">{t("comT8")}</p>
              <p className="w-3/4">{t("comT9")}</p>
            </div>
            <div className="flex border-b-[1.5px] border-[#2a92d3] pt-[30px] pb-[15px] mx-[20px] px-[3px] gap-x-[50px]">
              <p className="w-1/4">{t("comT10")}</p>
              <p className="w-3/4">{t("comT11")}</p>
            </div>
            <div className="flex border-b-[1.5px] border-[#2a92d3] pt-[30px] pb-[15px] mx-[20px] px-[3px] gap-x-[50px]">
              <p className="w-1/4">{t("comT12")}</p>
              <p className="w-3/4">{t("comT13")}</p>
            </div>
            <div className="flex border-b-[1.5px] border-[#2a92d3] pt-[30px] pb-[15px] mx-[20px] px-[3px] gap-x-[50px]">
              <p className="w-1/4 font-bold">{t("comT14")}</p>
              <a
                className="w-3/4 underline text-[#2a92d3] font-bold"
                href="/privacy"
              >
                {t("comT15")}
              </a>
            </div>
            <div className="bg-[url('D:\workspace\AllenChow\AiOnWeb3(react)\public\images\blueBack.png')] bg-cover bg-no-repeat px-[32px] py-[2px] my-[30px]">
              <p className="text-white font-bold md:text-[20px] ">
                {" "}
                {t("comT16")}
              </p>
            </div>

            <div className="xl:flex justify-between px-[10px] gap-x-[40px]">
              <div
                className="xl:w-3/5 py-1 px-2"
                data-aos="fade-in"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <div className="mb-[25px] w-full ">
                  <p className="whitespace-pre-wrap">{t("comT1")}</p>
                </div>
              </div>
              <div
                className="relative xl:w-2/5 mb-[30px] xl:mb-0"
                data-aos="fade-in"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <img
                  src="/images/DSC_7768-Edit-2-scaled.jpg"
                  alt=""
                  className="w-full drop-shadow-2xl shadow-black"
                />
              </div>
            </div>
            <div
              className="px-[20px]"
              data-aos="fade-in"
              data-aos-once="true"
              data-aos-delay="700"
            >
              <p className="whitespace-pre-wrap">{t("comT2")}</p>
            </div>
          </div>
        </div>
        <div className="absolute h-[100px] bottom-0" id="contact"></div>
      </div>
    </div>
  );
};

export default Company;
