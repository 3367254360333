import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Routes from "./Routes";
import Navbar from "./pages/Navbar";
import Footer from "./pages/Footer";


import "./App.css";

function App() {

  useEffect(() => {
    AOS.init({
      once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className="tracking-wider relative min-w-screen" style={{fontFamily: 'system-ui'}}>
      <Navbar />
      <Routes />
      <Footer />
    </div>
  );
}

export default App;
