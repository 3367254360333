import React, { useState, useEffect } from "react";
import Home from "./Home";
import Service from "./Service";
import News from "./News";
import Company from "./Company";
import Contact from "./Contact";
import "../../i18n";
import { useTranslation } from "react-i18next";
import useScrollPosition from "../../hook/ScrollPosition";

const slides = [
  {
    component: <Home />,
    content: 1,
  },
  {
    component: <Service />,
    content: 2,
  },
  {
    component: <News />,
    content: 3,
  },
  {
    component: <Company />,
    content: 4,
  },
  {
    component: <Contact />,
    content: 5,
  },
];

const Layout = () => {
  const { i18n } = useTranslation();

  const [visibleSlides, setVisibleSlides] = useState(slides);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollPosition = useScrollPosition();


  return (
    <div
      className={`text-[14px] md:text-[16px] leading-[1.8] tracking-wider text-[#222] ${
        i18n.language === "cn" && "font-thin"
      } `}
      style={{
        fontFamily:
          i18n.language === "jp"
            ? "YuGothic"
            : i18n.language === "en"
            ? "system-ui"
            : "YuGothicMd",
      }}
    >
      {visibleSlides.map(({ component, content }) => (
        <div key={content}>{component}</div>
      ))}
      <button
        className={`bg-[#2a92d3] bottom-0 right-0 h-[30px] w-[30px] md:h-[50px] md:w-[50px] fixed flex justify-center items-center ${
          scrollPosition > 100 ? "bg-[#2a92d3]" : ""
        }`}
        data-aos="fade-in"
        data-aos-once="true"
        data-aos-delay="300"
        onClick={() => scrollToSection("home")}
      >
        <svg
          className="svg-inline--fa fa-chevron-up w-[14px] md:w-[22px] text-white"
          aria-hidden="true"
          aria-labelledby="svg-inline--fa-title-WX4yKnc03PUN"
          data-prefix="fas"
          data-icon="chevron-up"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
          ></path>
        </svg>
      </button>
    </div>
  );
};

export default Layout;
