import { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import axios from "axios";

export default function CoinChart({ name }) {
  const [days, setDays] = useState(1);

  const [chartConfig, setChartConfig] = useState({
    type: "line",
    height: 440,
    width: "100%",
    series: [
      {
        name: "Prices",
        data: [50, 40, 300, 320, 500, 350, 200, 230, 500],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#FF0000"], // Changed color to red
      stroke: {
        lineCap: "round",
        curve: "smooth",
        width: 2,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        axisTicks: {
          show: false,
        },
        axisBorder: {
          show: true,
        },
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
          formatter: function (value) {
            if (typeof value === "string") {
              return value.split(",")[0]; // Remove time in label of date and return value
            }
            return value;
          },
        },
        categories: [],
        tickAmount: 5,
      },
      yaxis: {
        labels: {
          style: {
            colors: "#616161",
            fontSize: "12px",
            fontFamily: "inherit",
            fontWeight: 400,
          },
          formatter: function (value) {
            return value.toFixed(0); // Removes decimals
          },
        },
        axisBorder: {
          show: true,
        },
        tickAmount: 5,
      },
      grid: {
        show: true,
        borderColor: "#dddddd",
        strokeDashArray: 0,
        xaxis: {
          lines: {
            show: false, // Hide x-axis grid lines
          },
        },
        yaxis: {
          lines: {
            show: true, // Show y-axis grid lines
            style: {
              color: "#dddddd",
              dashArray: 4, // Dashed line for the top row
            },
          },
        },
        padding: {
          top: 5,
          right: 20,
        },
      },
      fill: {
        type: "gradient", // Use gradient fill
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#FF0000"], // Array of colors you want to gradient between
          inverseColors: true,
          opacityFrom: 0.9,
          opacityTo: 0.6,
          stops: [0, 50, 100],
        },
      },
      tooltip: {
        theme: "light",
      },
    },
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://api.coingecko.com/api/v3/coins/${name.toLowerCase()}/market_chart`;

        const historicalDataResponse = await axios.get(url, {
          params: {
            vs_currency: "usd",
            days,
          },
        });

        const historicalPrices = historicalDataResponse.data.prices;

        const historicalPricesData = historicalPrices.map((data) => data[1]);
        const historicalTimeData = historicalPrices.map(
          (data) => new Date(data[0]).toLocaleString() // Show date and time
        );

        setChartConfig((prevChartConfig) => ({
          ...prevChartConfig,
          series: [
            {
              ...prevChartConfig.series[0],
              data: historicalPricesData,
            },
          ],
          options: {
            ...prevChartConfig.options,
            xaxis: {
              ...prevChartConfig.options.xaxis,
              categories: historicalTimeData,
            },
          },
        }));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 3 * 60 * 1000); // Update real-time data every 3 minutes

    return () => clearInterval(interval);
  }, [name, days]);

  return (
    <Card className="px-[40px] w-full">
      <CardHeader
        floated={false}
        shadow={false}
        color="red"
        className="flex justify-between gap-4 px-[20px] font-medium text-[#333333]"
      >
        <div></div>
        <div className="flex space-x-[2px] px-[4px] py-[2px] bg-[#e0e3e6] rounded-md">
          <button className="flex justify-center items-center px-[7px] py-[3px] rounded-md hover:bg-white" value={1} onClick={() => setDays(1)}>1D</button>
          <button className="flex justify-center items-center px-[7px] py-[3px] rounded-md hover:bg-white" value={7} onClick={() => setDays(7)}>7D</button>
          <button className="flex justify-center items-center px-[7px] py-[3px] rounded-md hover:bg-white" value={30} onClick={() => setDays(30)}>1M</button>
          <button className="flex justify-center items-center px-[7px] py-[3px] rounded-md hover:bg-white" value={365} onClick={() => setDays(365)}>1Y</button>
        </div>
      </CardHeader>
      <CardBody className="">
        <Chart {...chartConfig} />
      </CardBody>
    </Card>
  );
}
