import "../../i18n";
import { useTranslation } from "react-i18next";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center w-full bg-[#f8f8f8]">
      <div className="flex flex-col relative w-[350px] sm:w-[500px] md:w-[700px] xl:w-[1300px]">
        <div className="text-center">
          <p
            className="text-[#85b7d9] text-[50px] md:text-[80px] font-bold"
            style={{ fontFamily: "system-ui" }}
          >
            Services
          </p>
          <p className="font-bold md:text-[20px]">商品・サービス</p>
        </div>
        <div className="md:flex justify-between items-center px-[30px] gap-x-6">
          <div
            className="md:w-1/2"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <div className="border-b-[3px] border-[#cce4ff] mb-[25px] w-full py-1 relative">
              <div className="absolute bottom-[-3px] bg-[#2a92d3] h-[3px] z-10 w-[110px]"></div>
              <p className="text-[20px] md:text-[36.8px] font-bold ">
                <span style={{ fontFamily: "system-ui" }}>GameFi</span>{" "}
                {t("serveT1")}
              </p>
            </div>
            <p>{t("serveT2")}</p> <br />
            <p>
              {t("serveT3")}{" "}
              <span style={{ fontFamily: "system-ui" }}>
                Industry Research
                (https://www.linkedin.com/pulse/gamefi-market-global-overview-size-projection-ycs7f/
                )
              </span>
            </p>
          </div>
          <div className="relative md:w-1/2 my-[50px]">
            <div
              data-aos="fade-in"
              data-aos-once="true"
              data-aos-delay="500"
              className="absolute top-[20px] left-[30px] bg-[#2a92d3] h-full w-full"
            ></div>
            <img
              data-aos="fade-in"
              data-aos-once="true"
              data-aos-delay="500"
              src="/images/image-1024x683-1.png"
              alt=""
              className="w-full drop-shadow-2xl shadow-black"
            />
          </div>
        </div>
        <div className="md:flex gap-x-[40px]">
          <div
            className="flex flex-col items-center md:w-1/2 gap-y-[20px] pb-[20px] md:pb-0"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <img src="/images/ai.png" alt="" className="h-[150px]" />
            <p className="font-bold text-[20px]">{t("serveT4")}</p>
            <p>{t("serveT5")}</p>
          </div>
          <div
            className="flex flex-col items-center md:w-1/2 gap-y-[20px]"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <img src="/images/ai2.png" alt="" className="h-[150px]" />
            <p className="font-bold text-[20px]">{t("serveT6")}</p>
            <p>{t("serveT7")}</p>
          </div>
        </div>

        <div className="xl:flex justify-between items-center pl-[30px] mt-[100px]">
          <div
            className="xl:w-1/2"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <div className="border-b-[3px] border-[#cce4ff] mb-[25px] w-full py-1 relative">
              <div className="absolute bottom-[-3px] bg-[#2a92d3] h-[3px] z-10 w-[110px]"></div>

              <p className="text-[20px] md:text-[36.8px] font-bold">{t("serveT8")}</p>
            </div>
            <p className="whitespace-pre-wrap">{t("serveT9")}</p>
            <br />
          </div>
          <div
            className="relative xl:w-1/2 m-[50px]"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <div className="absolute top-[20px] left-[30px] bg-[#2a92d3] h-full w-full"></div>
            <img
              src="/images/image-1-1-e1708995988322-768x537.png"
              alt=""
              className="w-full drop-shadow-2xl shadow-black"
            />
          </div>
        </div>
        <div
          className="px-[30px]"
          data-aos="fade-in"
          data-aos-once="true"
          data-aos-delay="500"
        >
          <p className="whitespace-pre-wrap">{t("serveT10")}</p>
        </div>

        <div className="xl:flex justify-between items-center pl-[30px] mt-[40px]">
          <div
            className="xl:w-1/2"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <div className="border-b-[3px] border-[#cce4ff] mb-[25px] w-full py-1 relative">
              <p className="text-[20px] md:text-[36.8px] font-bold">{t("serveT11")}</p>
              <div className="absolute bottom-[-3px] bg-[#2a92d3] h-[3px] z-10 w-[110px]"></div>
            </div>
            <p className="whitespace-pre-wrap">{t("serveT12")}</p>
            <br />
          </div>
          <div
            className="relative xl:w-1/2 m-[50px]"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <div className="absolute top-[20px] left-[30px] bg-[#2a92d3] h-full w-full"></div>
            <img
              src="/images/1-1-1920x1152.jpg"
              alt=""
              className="w-full drop-shadow-2xl shadow-black"
            />
          </div>
        </div>

        <div className="xl:flex justify-between items-center pl-[30px] my-[60px]">
          <div
            className="xl:w-1/2"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <div className="border-b-[3px] border-[#cce4ff] mb-[25px] w-full py-1 relative">
              <div className="absolute bottom-[-3px] bg-[#2a92d3] h-[3px] z-10 w-[110px]"></div>
              <p className="text-[20px] md:text-[36.8px] font-bold">{t("serveT13")}</p>
            </div>
            <p className="whitespace-pre-wrap">{t("serveT14")}</p>
            <br />
          </div>
          <div
            className="relative xl:w-1/2 m-[50px]"
            data-aos="fade-in"
            data-aos-once="true"
            data-aos-delay="500"
          >
            <div className="absolute top-[20px] left-[30px] bg-[#2a92d3] h-full w-full"></div>
            <img
              src="/images/2-2-1920x1152.jpg"
              alt=""
              className="w-full drop-shadow-2xl shadow-black"
            />
          </div>
        </div>
        <div className="absolute h-[100px] bottom-0" id="news"></div>
      </div>
    </div>
  );
};

export default Services;
