import { useTranslation } from "react-i18next";
import { useRouter } from "../../hook/use-router";
import "../../i18n";

const News = () => {
  const { t } = useTranslation();

  const router = useRouter();

  const notice1 = () => {
    router.push("/notice1");
  };
  const notice2 = () => {
    router.push("/notice1");
  };
  const notice3 = () => {
    router.push("/notice1");
  };

  return (
    <div className="bg-[url('D:\workspace\AllenChow\AiOnWeb3(react)\public\images\news.jpg')] bg-cover bg-no-repeat bg-center flex justify-center relative">
      <div className="absolute w-full h-full bg-black bg-opacity-10 top-0 z-10"></div>

      <div className="md:flex justify-center py-[100px] gap-x-[120px] text-white text-[14px] xl:text-[16px] text-opacity-90 relative w-[350px] sm:w-[500px] md:w-[700px] xl:w-[1300px]">
        <div className="text-center z-20">
          <p
            className="text-[60px] md:text-[80px] font-normal"
            style={{ fontFamily: "system-ui" }}
          >
            NEWS
          </p>
          <p
            className="text-[20px] font-bold mb-[20px] md:mb-0"
            style={{ fontFamily: "YuGothic" }}
          >
            新着情報
          </p>
        </div>
        <div
          className="z-40"
          data-aos="fade-in"
          data-aos-once="true"
          data-aos-delay="500"
        >
          <button className="w-full text-left" onClick={notice1}>
            <div className="border-y-[1.5px] py-[20px]">
              <p className="pb-2">{t("news1")}</p>
              <button className="bg-[#2a92d3] text-[14px] font-bold py-[4px] px-[10px] ">
                {t("news4")}
              </button>
            </div>
          </button>
          <button className="w-full text-left" onClick={notice2}>
            <div className="border-b-[1.5px] border-y-white py-[20px]">
              <p className="pb-2">{t("news2")}</p>
              <button className="bg-[#2a92d3] text-[14px] font-bold py-[4px] px-[10px]">
                {t("news4")}
              </button>
            </div>
          </button>
          <button className="w-full text-left" onClick={notice3}>
            <div className="border-b-[1.5px] border-y-white py-[20px]">
              <p className="pb-2">{t("news3")}</p>
              <button className="bg-[#2a92d3] text-[14px] font-bold py-[4px] px-[10px]">
                {t("news4")}
              </button>
            </div>
          </button>
        </div>
        <div className="absolute h-[100px] bottom-0" id="company"></div>
      </div>
    </div>
  );
};

export default News;
