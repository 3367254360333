import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./pages/Home/Layout";
import Notice1 from "./pages/News/Notice1";
import Privacy from "./pages/Company/Privacy";
import CoinMarket from "./pages/Market/CoinMarket";
import NFTMarket from "./pages/Market/GameMarket";


const Router = () => {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />} />
        <Route path="/notice1" element={<Notice1 />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/coin" element={<CoinMarket />} />
        <Route path="/game" element={<NFTMarket />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;