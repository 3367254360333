import { useState, useEffect } from "react";
import Chart from "./DetailChart";

const Detail = (props) => {
  const [coinData, setCoinData] = useState(props.data);

  useEffect(() => {
    setCoinData(props.data);
  }, [props.data]);

  const formatNumber = (number) => {
    return number.toLocaleString("en-US", {
      maximumFractionDigits: 2,
    });
  };

  return (
    <>
      {coinData && (
        <div
          id="default-modal"
          tabindex="-1"
          className={`${
            props.open ? "flex" : "hidden"
          } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full`}
        >
          <div className="relative w-[1600px] p-4">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <div className="flex items-center justify-between px-[30px] py-[15px] border-b rounded-t dark:border-gray-600 bg-[#2a92d3]">
                <h3 className="text-xl font-semibold text-white">
                  Coin Detail
                </h3>
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  data-modal-hide="default-modal"
                  onClick={props.close}
                >
                  <svg
                    className="w-3 h-3 text-white"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="flex md:flex-row flex-col space-y-4">
                <div className="flex flex-col w-full md:w-1/3 gap-[10px] border-gray border-r-[1.5px] px-[40px] py-[40px]">
                  <div className="flex gap-x-[10px] items-center">
                    <div className="flex items-center">
                      <img
                        src={`https://cryptologos.cc/logos/${coinData.slug}-${coinData.symbol}-logo.svg?v=032`}
                        alt={coinData.slug}
                        className="h-[25px] w-[25px]"
                      />
                    </div>
                    <p className="font-bold text-[18px]">
                      {coinData.name.slice(0, 12)}
                    </p>
                    <p className="font-bold text-[#777] uppercase">
                      {coinData.symbol.slice(0, 12)}
                    </p>
                  </div>
                  <div className="flex gap-x-[15px] my-[10px] items-cneter">
                    <p className="text-[24px] font-bold text-black">
                      $
                      {formatNumber(
                        Number(coinData.quote.USD.price.toFixed(2))
                      )}
                    </p>
                    <p
                      className={`flex items-center ${
                        coinData.quote.USD.percent_change_1h > 0
                          ? "text-[#059142]"
                          : "text-[#ff2c2c]"
                      }`}
                    >
                      {formatNumber(
                        Number(coinData.quote.USD.percent_change_1h.toFixed(2))
                      )}
                      %
                    </p>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="flex w-full items-center space-x-[4px]">
                      <p className="flex items-center">Market cap</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        height="16"
                        width="16"
                        viewBox="0 0 24 24"
                        color="neutral4"
                        className="sc-4c05d6ef-0 fFPkto text-[#444] mt-[4px]"
                      >
                        <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                      </svg>
                    </div>
                    <div>
                      <p>
                        {" "}
                        $
                        {formatNumber(
                          Number(coinData.quote.USD.market_cap.toFixed(0))
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <div className="flex w-full items-center space-x-[4px]">
                      <p>Volume (24h)</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        height="16"
                        width="16"
                        viewBox="0 0 24 24"
                        color="neutral4"
                        className="sc-4c05d6ef-0 fFPkto text-[#444] mt-[2px]"
                      >
                        <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                      </svg>
                    </div>
                    <div className="flex">
                      <p></p>
                      <p>
                        $
                        {formatNumber(
                          Number(coinData.quote.USD.volume_24h.toFixed(0))
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="flex w-[1/2] items-center space-x-[3px]">
                      <p>Circulating supply</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        height="16"
                        width="16"
                        viewBox="0 0 24 24"
                        color="neutral4"
                        className="sc-4c05d6ef-0 fFPkto text-[#444] mt-[4px]"
                      >
                        <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                      </svg>
                    </div>
                    <div>
                      <p>
                        {formatNumber(
                          Number(coinData.circulating_supply.toFixed(0))
                        )}
                        <span className="uppercase"> {coinData.symbol}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="flex w-[1/2] items-center space-x-[3px]">
                      <p>Total supply</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        height="16"
                        width="16"
                        viewBox="0 0 24 24"
                        color="neutral4"
                        className="sc-4c05d6ef-0 fFPkto text-[#444] mt-[4px]"
                      >
                        <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                      </svg>
                    </div>
                    <div>
                      <p>
                        {formatNumber(Number(coinData.total_supply.toFixed(0)))}
                        <span className="uppercase"> {coinData.symbol}</span>
                      </p>
                    </div>
                  </div>
                  <div className="flex w-full justify-between">
                    <div className="flex w-[1/2] items-center space-x-[3px]">
                      <p>Max supply</p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        height="16"
                        width="16"
                        viewBox="0 0 24 24"
                        color="neutral4"
                        className="sc-4c05d6ef-0 fFPkto text-[#444] mt-[4px]"
                      >
                        <path d="M12 2C6.48583 2 2 6.48583 2 12C2 17.5142 6.48583 22 12 22C17.5142 22 22 17.5142 22 12C22 6.48583 17.5142 2 12 2ZM14.215 17.2367C13.6642 17.4533 11.755 18.365 10.655 17.3958C10.3267 17.1075 10.1633 16.7417 10.1633 16.2975C10.1633 15.4658 10.4367 14.7408 10.9292 13C11.0158 12.6708 11.1217 12.2442 11.1217 11.9058C11.1217 11.3217 10.9 11.1667 10.2992 11.1667C10.0058 11.1667 9.68083 11.2708 9.38667 11.3808L9.54917 10.715C10.205 10.4483 11.0283 10.1233 11.7333 10.1233C12.7908 10.1233 13.5692 10.6508 13.5692 11.6542C13.5692 11.9433 13.5192 12.45 13.4142 12.8L12.8058 14.9517C12.68 15.3867 12.4525 16.3458 12.805 16.63C13.1517 16.9108 13.9725 16.7617 14.3775 16.5708L14.215 17.2367ZM13.21 8.66667C12.52 8.66667 11.96 8.10667 11.96 7.41667C11.96 6.72667 12.52 6.16667 13.21 6.16667C13.9 6.16667 14.46 6.72667 14.46 7.41667C14.46 8.10667 13.9 8.66667 13.21 8.66667Z"></path>
                      </svg>
                    </div>
                    <div>
                      <p>
                        {formatNumber(Number(coinData.max_supply))}
                        <span className="uppercase"> {coinData.symbol}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center w-full md:w-2/3 px-[30px] py-[20px]">
                  <Chart name={coinData.name} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Detail;
