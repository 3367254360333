import "../../i18n";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center w-full bg-[#f8f8f8] ">
      <div
        className="flex flex-col relative py-[50px] text-[14px] md:text-[16px] xl:text-[18px] w-[320px] sm:w-[400px] md:w-[650px] xl:w-[850px]"
        data-aos="fade-in"
        data-aos-once="true"
        data-aos-delay="700"
      >
        <div className="text-center">
          <p
            className="text-[#85b7d9] text-[40px] md:text-[60px] xl:text-[80px] font-bold"
            style={{ fontFamily: "system-ui" }}
          >
            CONTACT
          </p>
          <p
            className="font-bold text-[14px] md:text-[20px] mb-[40px]"
            style={{ fontFamily: "YuGothic" }}
          >
            お問い合わせ
          </p>
        </div>
        <div className="md:flex items-center border-y-[1.5px] border-[#d9d9d3] py-[40px] mx-[20px] px-[3px] gap-x-[50px] pr-[40px]">
          <p className="md:w-1/3 font-bold  border-[#ccc] mb-[4px] md:mb-0">
            {t("contact1")}
          </p>
          <input
            className="bg-[#e8e8e8] border-[#ccc] border-[1px] rounded-[4px] w-full md:w-2/3 h-[44px] px-3"
            placeholder={t("contact2")}
          />
        </div>
        <div className="md:flex items-center border-b-[1.5px] border-[#d9d9d3] py-[40px] mx-[20px] px-[3px] gap-x-[50px] pr-[40px]">
          <div className="flex items-center md:w-1/3 gap-x-5 mb-[4px] md:mb-0">
            <p className="font-bold  border-[#ccc]">{t("contact3")}</p>
            <div className="flex justify-center items-center bg-[#2a92d3] text-white w-[50px] h-[25px] text-[13px] font-bold">
              {t("contact9")}
            </div>
          </div>
          <input
            className="bg-[#e8e8e8] border-[#ccc] border-[1px] rounded-[4px] w-full md:w-2/3 h-[44px] px-3"
            placeholder={t("contact4")}
          />
        </div>
        <div className="md:flex items-center border-b-[1.5px] border-[#d9d9d3] py-[40px] mx-[20px] px-[3px] gap-x-[50px] pr-[40px]">
          <div className="flex items-center md:w-1/3 gap-x-5 mb-[4px] md:mb-0">
            <p className="font-bold  border-[#ccc]">{t("contact5")}</p>
            <div className="flex justify-center items-center bg-[#2a92d3] text-white w-[50px] h-[25px] text-[13px] font-bold">
              {t("contact9")}
            </div>
          </div>
          <input className="bg-[#e8e8e8] border-[#ccc] border-[1px] rounded-[4px] w-full md:w-2/3 h-[44px] px-3" />
        </div>
        <div className="md:flex items-center border-b-[1.5px] border-[#d9d9d3] py-[40px] mx-[20px] px-[3px] gap-x-[50px] pr-[40px]">
          <p className="w-1/3 font-bold  border-[#ccc] mb-[4px] md:mb-0">
            {t("contact6")}
          </p>
          <input className="bg-[#e8e8e8] border-[#ccc] border-[1px] rounded-[4px] w-full md:w-2/3 h-[44px] px-3" />
        </div>
        <div className="md:flex items-center border-b-[1.5px] border-[#d9d9d3] py-[40px] mx-[20px] px-[3px] gap-x-[30px]">
          <div className="flex items-center md:w-1/3 gap-x-2 mb-[4px] md:mb-0">
            <p className="font-bold  border-[#ccc]">{t("contact7")}</p>
            <div className="flex justify-center items-center bg-[#2a92d3] text-white w-[50px] h-[25px] text-[13px] font-bold">
              {t("contact9")}
            </div>
          </div>
          <textarea
            rows={5}
            className="bg-[#e8e8e8] border-[#ccc] border-[1px] rounded-[4px] w-full md:w-2/3 "
          />
        </div>
        <div className="md:flex items-center border-b-[1.5px] border-[#d9d9d3] py-[40px] mx-[20px] px-[3px] gap-x-[50px] pr-[40px]">
          <p className="w-1/3 font-bold  border-[#ccc] mb-[4px] md:mb-0">
            {t("contact8")}
          </p>
          <input className="bg-[#e8e8e8] border-[#ccc] border-[1px] rounded-[4px] w-full md:w-2/3 h-[44px] px-3" />
        </div>
        <button className="bg-[#2a92d3] text-white w-[270px] md:w-[470px] h-[50px] rounded-sm mx-auto my-[70px] text-[16px]">
          {t("contact10")}
        </button>
      </div>
    </div>
  );
};

export default Contact;
