import { useState } from "react";
import "../i18n";
import { useTranslation } from "react-i18next";
import {
  Drawer,
  Menu,
  MenuHandler,
  Button,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";

import useScrollPosition from "../hook/ScrollPosition";
import { Link as ScrollLink } from "react-scroll";
import MenuCustomList from "../components/Menu";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const currentUrl = window.location.pathname;
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollPosition = useScrollPosition();

  const navItems = [
    { id: "services", label: "Services" },
    { id: "news", label: "News" },
    { id: "company", label: "Company" },
    { id: "contact", label: "Contact" },
  ];

  const { i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div
      className={`fixed justify-center py-[10px] flex ${
        scrollPosition > 0 || currentUrl !== "/" ? "bg-[#2a92d3]" : ""
      } w-full items-center z-50`}
    >
      <div className="flex justify-between  w-[1280px]  items-center">
        <div className="flex items-center ">
          <a className="flex w-[200px] md:w-[280px]" href="/">
            <img src="/images/logo_ai.png" alt="Logo" className="w-full" />
          </a>
        </div>
        <div className="xl:flex gap-x-4 hidden">
          <div className="w-[40px] md:w-[40px]">
            <a href="https://t.me/aionweb3">
              <img src="/images/tg.png" alt="Telegram" className="w-full" />
            </a>
          </div>
          <div className="w-[40px] md:w-[40px]">
            <a href="https://x.com/AIonWeb3">
              <img src="/images/twitter.png" alt="Twitter" className="w-full" />
            </a>
          </div>
        </div>

        <div className="xl:flex justify-between items-center space-x-3 text-bold hidden">
          <a href="/" className="relative">
            <p
              className={`text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold text-[14.2px] ${
                currentUrl === "/" && "hidden"
              }`}
            >
              Home
            </p>
          </a>
          <div className="flex items-center">
            <div class="relative inline-block text-left">
              <button
                type="button"
                className="text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold text-[14.2px]"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
                onClick={() => setMenu(true)}
              >
                AOW Market Place
              </button>

              <div
                className={`absolute right-[-60px] z-10 w-[280px] origin-top-right rounded-md bg-white shadow-lg focus:outline-none ${
                  menu ? "flex" : "hidden"
                }`}
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
                tabindex="-1"
                onMouseLeave={() => setMenu(false)}
              >
                <div
                  className="z-50 px-[10px] text-start py-[18px]"
                  role="none"
                >
                  <a
                    className="text-[#2a92d3] hover:text-[#a6c2ef80] transition-colors duration-300 px-3 pb-auto cursor-pointer font-bold text-[14.2px] flex items-center mb-[5px]"
                    href="/coin"
                  >
                    General Coin Market
                  </a>
                  <a
                    className="text-[#2a92d3] hover:text-[#a6c2ef80] transition-colors duration-300 px-3 pt-auto cursor-pointer font-bold text-[14.2px] flex items-center"
                    href="/game"
                  >
                    GameFi and NFT Market
                  </a>
                </div>
              </div>
            </div>
            {/* <Menu>
              <MenuHandler>
                <button className="text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold text-[14.2px]">
                  AOW Market Place
                </button>
              </MenuHandler>
              <MenuList className="z-50 w-[280px] text-start px-[14px] py-[15px]" >
                <MenuItem className="flex items-center">
                  <a
                    className="text-[#2a92d3] hover:text-[#a6c2ef80] transition-colors duration-300 px-3 pb-auto cursor-pointer font-bold text-[14.2px] flex items-center"
                    href="/coin"
                    style={{ border: 'none' }}
                  >
                    General Coin Market
                  </a>
                </MenuItem>
                <MenuItem className="flex items-center">
                  <a
                    className="text-[#2a92d3] hover:text-[#a6c2ef80] transition-colors duration-300 px-3 pt-auto cursor-pointer font-bold text-[14.2px] flex items-center"
                    href="/game"
                    style={{ border: 'none' }}
                  >
                    GameFi and NFT Market
                  </a>
                </MenuItem>
              </MenuList>
            </Menu> */}
          </div>

          <div
            className={`flex items-center ${
              currentUrl !== "/" && "hidden"
            } space-x-3`}
          >
            {navItems.map((item) => (
              <ScrollLink
                key={item.id}
                to={item.id}
                smooth={true}
                duration={500}
                className="text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold text-[14.2px]"
                onClick={() => scrollToSection(item.id)}
              >
                {item.label}
              </ScrollLink>
            ))}
          </div>

          <div className="xl:flex items-center space-x-[7px] text-white px-3 py-2 hidden text-[13px]">
            <button
              className="flex items-center font-bold"
              key="jp"
              value="jp"
              onClick={() => changeLanguage("jp")}
              style={{ fontFamily: "YuGothic" }}
            >
              日本語
            </button>
            <button
              className="flex items-center font-bold px-[7px] border-white border-l-2 border-r-2"
              key="en"
              value="en"
              onClick={() => changeLanguage("en")}
            >
              English
            </button>
            <button
              className="flex items-center font-bold"
              key="cn"
              value="cn"
              onClick={() => changeLanguage("cn")}
              style={{ fontFamily: "YuGothic" }}
            >
              中文
            </button>
          </div>
        </div>
        <button
          className="flex flex-col justify-center xl:hidden h-5 w-5 mr-[30px]"
          onClick={() => setOpen(!open)}
        >
          <div className="sr-only">{open ? "Close menu" : "Open menu"}</div>
          <div
            aria-hidden="true"
            className={`absolute h-0.5 w-6 bg-white transition duration-300 ease-in-out ${
              open ? "rotate-45" : "-translate-y-1.5"
            }`}
          />
          <div
            aria-hidden="true"
            className={`absolute h-0.5 w-6 bg-white transition duration-300 ease-in-out ${
              open ? "opacity-0" : "opacity-100"
            }`}
          />
          <div
            aria-hidden="true"
            className={`absolute h-0.5 w-6 bg-white transition duration-300 ease-in-out ${
              open ? "-rotate-45" : "translate-y-1.5"
            }`}
          />
        </button>
      </div>
      <Drawer
        open={open}
        overlay={false}
        className="p-4 bg-[#2a92d3] text-white"
      >
        <div className="mb-6 flex items-center justify-between border-white border-b-[1px] pb-[15px]">
          <div className="w-[150px]">
            <img src="/images/logo_ai.png" alt="logo" />
          </div>
        </div>
        <div className="flex flex-col justify-between text-bold text-[16px]">
          <a href="/" className="relative">
            <p
              className={`text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold text-[14.2px] ${
                currentUrl === "/" && "hidden"
              }`}
            >
              Home
            </p>
          </a>
          <a href="/coin">
            <p className="text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold text-[14.2px]">
              General Coin market
            </p>
          </a>
          <a href="/game">
            <p className="text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold text-[14.2px]">
              GameFi and NFT Market
            </p>
          </a>
          <div
            className={`flex flex-col justify-center ${
              currentUrl !== "/" && "hidden"
            }`}
          >
            {navItems.map((item) => (
              <ScrollLink
                key={item.id}
                to={item.id}
                smooth={true}
                duration={500}
                className="text-white hover:text-[#a6c2ef80] transition-colors duration-300 px-3 py-2 cursor-pointer font-bold"
                onClick={() => scrollToSection(item.id)}
              >
                {item.label}
              </ScrollLink>
            ))}
          </div>
          <div className="flex items-center space-x-[7px] text-white px-3 mr-[100px] text-[13px] my-[10px] py-[20px] border-y-[1px] border-white">
            <button
              className="flex items-center font-bold"
              key="jp"
              value="jp"
              onClick={() => changeLanguage("jp")}
              style={{ fontFamily: "YuGothic" }}
            >
              日本語
            </button>
            <button
              className="flex items-center font-bold px-[7px] border-white border-l-2 border-r-2"
              key="en"
              value="en"
              onClick={() => changeLanguage("en")}
            >
              English
            </button>
            <button
              className="flex items-center font-bold"
              key="cn"
              value="cn"
              onClick={() => changeLanguage("cn")}
              style={{ fontFamily: "YuGothic" }}
            >
              中文
            </button>
          </div>
          <div className="flex gap-x-4 px-3 py-2">
            <div className="w-[30px] md:w-[30px]">
              <a href="https://t.me/aionweb3">
                <img src="/images/tg.png" alt="Telegram" className="w-full" />
              </a>
            </div>
            <div className="w-[30px] md:w-[30px]">
              <a href="https://x.com/AIonWeb3">
                <img
                  src="/images/twitter.png"
                  alt="Twitter"
                  className="w-full"
                />
              </a>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Navbar;
